export function linkResolver(doc) {
  switch (doc.type) {
    case "page":
      return doc.uid === "home" ? "/" : `/${doc.uid}`
    case "project":
      return `/projects/${doc.uid}`
    default:
      return `/${doc.uid}`
  }
}
